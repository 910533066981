<template>
  <div class="invited-member-table">
    <SectionTitle hideBtn style="margin-bottom: 0">
      <template slot="title">
        <p style="font-size: 18px">獎勵發放紀錄列表</p>
      </template>
    </SectionTitle>

    <!-- <SearchArea
      :search="search"
      @search="refresh"
      @clear="refresh(true)"
    /> -->
    <div class="filters-wrapper">
      <div class="filter-row">
        <p class="label">篩選</p>
        <BaseElSelect v-model="search.sendStatus" class="test" placeholder="請選擇發送狀態" clearable @change="refresh(true)">
          <BaseElSelectOption
            v-for="option in sendStatusConfig"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </BaseElSelect>
        <el-date-picker
          v-model="search.range"
          editable
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
          @change="refresh(true)"
        />
      </div>
      <div class="filter-row">
        <p class="label">搜尋</p>
        <MemberSearch class="test2" :model.sync="search.member" @change="refresh(true)" />
        <BaseElSelect
          v-model="search.eventTemplateConfigSource"
          class="test2"
          placeholder="請選擇事件類型"
          clearable
          @change="refresh(true)"
        >
          <BaseElSelectOption
            v-for="item in featureKeysByEventSourceConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
        <BaseElSelect
          v-model="search.EventTemplateConfigId"
          class="test2"
          placeholder="請選擇活動"
          clearable
          @change="refresh(true)"
        >
          <BaseElSelectOption
            v-for="item in eventList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </BaseElSelect>
        <BaseElSelect
          v-model="search.awardType"
          class="test2"
          placeholder="請選擇行銷項目類型"
          clearable
          @change="refresh(true), search.awardId = null"
        >
          <BaseElSelectOption
            v-for="item in eventAwardConfigList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
        <BaseElSelect
          v-if="['classTicket'].includes(search.awardType) && features.useClassTicket"
          v-model="search.awardId"
          class="test2"
          placeholder="請選擇行銷項目"
          clearable
          @change="refresh(true)"
        >
          <BaseElSelectOption
            v-for="item in classTicketLsit"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </BaseElSelect>

        <BaseElSelect
          v-if="['coupon'].includes(search.awardType) && features.useCoupon || features.useCouponExchange"
          v-model="search.awardId"
          class="test2"
          placeholder="請選擇行銷項目"
          clearable
          @change="refresh(true)"
        >
          <BaseElSelectOption
            v-for="item in allCouponList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </BaseElSelect>
      </div>
      <div class="filter-row">
        <p class="label">排序</p>
        <BaseElSelect v-model="search.sortBy" clearable @change="refresh(true)">
          <BaseElSelectOption
            v-for="option in orderByOptionsConfig"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </BaseElSelect>
      </div>
    </div>
    <EventRecordTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router/composables'
// import EmptyBlock from '@/components/EmptyBlock.vue'
import EventRecordTable from './EventRecordTable.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import { usePermissions } from '@/use/permissions'
import { useTable } from '@/use/table'
import { get, map, find, omitBy, isArray, isNull } from 'lodash'
import { eventSourceConfig, sendStatusConfig, orderByOptionsConfig, eventAwardConfig, eventTypeConfig } from '@/config/marketing'
import { GetEventPlaybookAwardRecord, GetEventPlaybookAwardRecordCount, GetEventMarketingList } from '@/api/eventMarketing'
import { formatDate, isInvalidDate } from '@/utils/date'
import { GetClassTicket } from '@/api/classTicket'
import { GetCoupon } from '@/api/lottery/coupon'
import { GetCouponExchange } from '@/api/coupon'

export default defineComponent({
  name: 'EventRecordList',
  components: {
    // EmptyBlock,
    EventRecordTable,
    MemberSearch,
    // SearchArea,
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { checkAction, checkActionList } = usePermissions()
    const { loading, shopId, tableData, fetchData, fetchDataCount, tableDataCount, tableOptions, pageStartIndex, extendData, updateRouteQuery } = useTable()
    const eventList = ref([])
    const classTicketLsit = ref([])
    const couponList = ref([])
    const couponExchangeList = ref([])
    extendData('search', {
      sortBy: null,
      sendStatus: null,
      isSend: null,
      range: null,
      member: null,
      eventTemplateConfigSource: null,
      EventTemplateConfigId: null,
      awardType: null,
      awardId: null,
    })
    const search = reactive({
      member: null,
    })

    //  mapping featureKeys
    const featureKeys = {
      saleRecordCompleted: 'admin.salesRecord.page',
      ecOrderCompleted: 'admin.ecommercePage.page',
      memberStoreOrderCompleted: 'admin.memberStoreOrder.page',
      appointmentOrderCompleted: 'admin.appointmentService.page',
      memberExternalTransactionCompleted: ['admin.memberExternalTransaction.page', 'admin.shopCustomModule.posPalMemberWallet'],
    }
    const features = reactive({
      usePoint: computed(() => checkAction('admin.shopPoint.page')),
      useChashback: computed(() => checkAction('admin.shopCashback.page')),
      useClassTicket: computed(() => checkAction('admin.classTicket.page')),
      useCouponExchange: computed(() => checkAction('admin.couponExchange.page')),
      useCoupon: computed(() => checkAction('admin.coupon.page')),
    })
    const featureKeysByEventSourceConfig = computed(() => {
      return eventSourceConfig.filter(({ value }) => {
        const featureKey = get(featureKeys, value)
        if (!featureKey) return true
        if (isArray(featureKey)) return checkActionList(featureKey, 'intersection')
        return checkAction(featureKey)
      })
    })
    const eventAwardConfigList = computed(() => {
      const omitList = []
      if (!features.usePoint) omitList.push('point')
      if (!features.useChashback) omitList.push('cashback')
      if (!features.useClassTicket) omitList.push('classTicket')
      if (!features.useCouponExchange && !features.useCoupon) omitList.push('coupon')
      return omitBy(eventAwardConfig, (item) => omitList.includes(item.value))
    })
    const getTableData = async () => {
      const range = get(search, 'range')
      if (range && !isInvalidDate(range[0]) && !isInvalidDate(range[1])) {
        search.startCreatedAt = range[0]
        search.endCreatedAt = range[1]
      } else {
        search.startCreatedAt = null
        search.endCreatedAt = null
      }
      let isSend = null
      let errorAtIsNull = null
      const sendStatus = get(search, 'sendStatus')

      switch (sendStatus) {
      case 'success':
        isSend = true
        break
      case 'failed':
        isSend = false
        errorAtIsNull = false
        break
      case 'pending':
        isSend = false
        errorAtIsNull = true
        break
      }
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        awardType: get(search, 'awardType') || undefined,
        isSend: isNull(isSend) ? undefined : isSend,
        errorAtIsNull: isNull(errorAtIsNull) ? undefined : errorAtIsNull,
        startCreatedAt: get(search, 'startCreatedAt') || undefined,
        endCreatedAt: get(search, 'endCreatedAt') || undefined,
        startSendAt: get(search, 'startSendAt') || undefined,
        endSendAt: get(search, 'endSendAt') || undefined,
        MemberId: get(search, 'member.id') || undefined,
        EventTemplateConfigId: get(search, 'EventTemplateConfigId') || undefined,
        eventTemplateConfigSource: get(search, 'eventTemplateConfigSource') || undefined,
        awardId: ['classTicket', 'coupon'].includes(get(search, 'awardType')) && get(search, 'awardId') !== '' ? get(search, 'awardId') : undefined,
        sort: 'createdAt',
        sortBy: get(search, 'sortBy') || undefined,
      }
      await Promise.allSettled([
        fetchData(GetEventPlaybookAwardRecord, payload),
        fetchDataCount(GetEventPlaybookAwardRecordCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      console.log('refresh')
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }
    const getEventMarketingList = async () => {
      const payload = {
        shopId: shopId.value,
      }
      const [res, err] = await GetEventMarketingList(payload)
      if (err) window.$message.error(err)
      eventList.value = res
    }
    const getClassTicket = async () => {
      const payload = {
        shopId: shopId.value,
      }
      const [res, err] = await GetClassTicket(payload)
      if (err) window.$message.error(err)
      classTicketLsit.value = res
    }
    const getCoupon = async () => {
      const payload = {
        shopId: shopId.value,
      }
      const [res, err] = await GetCoupon(payload)
      if (err) window.$message.error(err)
      couponList.value = res
    }
    const getCouponExchange = async () => {
      const payload = {
        shopId: shopId.value,
      }
      const [res, err] = await GetCouponExchange(payload)
      if (err) window.$message.error(err)
      couponExchangeList.value = res
    }
    const allCouponList = computed(() => {
      return [...couponList.value, ...couponExchangeList.value]
    })
    onMounted(async () => {
      await getEventMarketingList()
      if (features.useClassTicket) getClassTicket()
      if (features.useCoupon) getCoupon()
      if (features.useCouponExchange) getCouponExchange()
      refresh()
    })

    return {
      loading,
      refresh,
      search,
      tableOptions,
      tableData,
      tableDataCount,
      get,
      sendStatusConfig,
      featureKeysByEventSourceConfig,
      eventAwardConfigList,
      orderByOptionsConfig,
      eventList,
      classTicketLsit,
      allCouponList,
      features,
    }
  },
})
</script>
<style scoped lang="postcss">
::v-deep .test.el-select {
  max-width: 154px !important;
}
::v-deep .test .el-input {
  max-width: 154px !important;
}
::v-deep .test2.el-select {
  max-width: 208px !important;
}
::v-deep .test2.el-input {
  width: 100% !important;
  max-width: 208px !important;
}
::v-deep .test2 .el-input {
  width: 100% !important;
  max-width: 208px !important;
}
::v-deep .test2 .el-input__inner {
  width: 100% !important
}
.filters-wrapper {
  @apply flex flex-col gap-[20px] mb-[20px];
}
.filter-row {
  @apply flex items-center gap-[8px];
}

.filter-row .label {
  @apply flex-shrink-0;
}
</style>
