<template>
  <div class="invited-member-table">
    <SectionTitle hideBtn style="margin-bottom: 0">
      <template slot="title">
        <p style="font-size: 18px">事件發放列表</p>
      </template>
    </SectionTitle>
    <BaseTable v-loading="loading" :data="paginatedData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="活動名稱" prop="name" align="center" />
      <BaseElTableColumn label="行銷項目類型" prop="type" align="center" />
      <BaseElTableColumn label="已發出行銷項目數量" prop="availableTimes" align="center" />
      <BaseElTableColumn label="已使用行銷項目數量" prop="usedTimes" align="center" />
      <BaseElTableColumn label="使用率" prop="useRate" align="center" />
    </BaseTable>
    <Pagination
      :curPage.sync="currentPage"
      :pageLimit="itemsPerPage"
      :total="displayData.length"
      @pageChange="updatePage"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { get, map, find } from 'lodash'
import { eventAwardConfig } from '@/config/marketing'

export default defineComponent({
  name: 'EventTable',
  components: {
    EmptyBlock,
  },
  props: {
    tableData: {
      type: Object,
    },
    loading: { type: Boolean, default: true },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const itemsPerPage = 10;
    const currentPage = ref(1);
    const unitLabel = {
      lineMessage: '筆',
      point: '點',
      coupon: '張',
      cashback: '元',
      classTicket: '組',
    }

    const displayData = computed(() => {
      return map(get(props, 'tableData.list'), (i) => {
        const awardType = get(i, 'awardType')
        const awardConfig = find(eventAwardConfig, { value: awardType })
        const marketingItemLabel = get(awardConfig, 'label')
        const usedTimes = (awardType) => {
          switch (awardType) {
            case 'lineMessage':
              return '(圖文訊息無法認列使用來源)';
            case 'point':
              return '(點數無法認列使用來源)';
            case 'cashback':
              return '(回饋金無法認列使用來源)';
            default:
              return `+${get(i, 'usedCount')}${get(unitLabel, awardType)}`;
          }
        }
        const useRate = ['lineMessage', 'point', 'cashback'].includes(awardType) ? '-' : (get(i, 'usedCount') / get(i, 'totalCount') * 100).toFixed(0) + '%' || '0%'
        return {
          name: get(i, 'eventPlaybookName'),
          type: marketingItemLabel,
          availableTimes: `+${get(i, 'totalCount')}${get(unitLabel, awardType)}`,
          usedTimes: usedTimes(awardType),
          useRate: useRate,
        }
      })
    })
    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      return displayData.value.slice(start, end);
    });

    const updatePage = (page) => {
      currentPage.value = page;
    };

    return {
      displayData,
      paginatedData,
      currentPage,
      itemsPerPage,
      updatePage,
    }
  },
})
</script>
