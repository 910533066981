import { admin2 } from '@/api/instance'

export const CheckCouponLinePush = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `${shopId}/coupon/checkLinePushConfig`,
  })
}
// => 創建使用券
export const CreateCoupon = async ({
  shopId,
  image,
  name,
  expType,
  expAt,
  expDay,
  endOfPeriodUnit,
  showMinute,
  notice,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/coupon`,
    data: {
      image,
      name,
      expType,
      expAt,
      expDay,
      endOfPeriodUnit,
      showMinute,
      notice,
    },
  })
  return res
}

// => 取得使用券
export const GetCoupon = async ({ shopId, start, limit, name, type }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/coupon`,
    params: { start, limit, name, type },
  })
  return res
}

// => 取得使用券總數
export const GetCouponCount = async ({ shopId, start, limit, type }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/coupon/count`,
    params: { start, limit, type },
  })
  return res
}

// => 查找使用券
export const FindCoupon = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/coupon/${id}`,
  })
  return res
}

// => 更新使用券
export const UpdateCoupon = async ({
  shopId,
  id,
  image,
  name,
  expType,
  expAt,
  expDay,
  showMinute,
  notice,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/coupon/${id}`,
    data: {
      image,
      name,
      expType,
      expAt,
      expDay,
      showMinute,
      notice,
    },
  })
  return res
}

// => 刪除使用券
export const DeleteCoupon = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/coupon/${id}`,
  })
  return res
}
